@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css);

@media (hover: none) {
    .share_tooltip {
        display: none !important;
    }
}


@media screen and (max-width:1310px) {
    body .column_container {
        display: flex;
        width: 100%;
    }

    body .left_column,
    body .right_column {
        display: none;
    }
}

@media screen and (max-width:780px) {

    body .column {
        margin: 0;
        padding: 7px;
        max-width: none;
        flex-flow: column nowrap;
        overflow: hidden;
    }

    body .column .row {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
    }

    body .item_cell {
        width: 100%;
    }

    body .item_cell.full {
        height: auto;
        padding: 0;
        min-height: none;
        border-radius: 0;
        margin: 10px 0;
    }

    body .item_cell.full .title {
        width: calc(100% - 20px);
    }

    body .column .row[data-count_child="2"]:not(.row.full) .item_cell {
        display: flex;
        height: 160px;
        box-shadow: 0 0 5px 1px rgba(138, 138, 138, 0.2);
    }

    body .column .row[data-count_child="2"]:not(.row.full) .contentElement {
        background-color: #fff !important;
        color: black !important;
    }

    body .column .row[data-count_child="2"] .gradient {
        display: none;
    }
}

body iframe {
    width: 100% !important;
}

.loader {
    cursor: pointer;
    font-size: 10px;
    margin: 20px auto;
    text-indent: -9999em;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #cccccc;
    background: linear-gradient(to right, #cccccc 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    animation: load3 .5s infinite linear;
    transform: translateZ(0);
}

.loader:before {
    width: 50%;
    height: 50%;
    background: #cccccc;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.loader:after {
    background: #F2F2F2;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}


@keyframes load3 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.App {
    width: 100%;
    height: 100%;
    padding: 3px 0;
}

.column_container {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr minmax(100px, 817px) 1fr;
    background-color: #F2F2F2;
}

.left_column,
.right_column {
    width: 100%;
    height: fit-content;
    overflow: hidden;
}

.left_column {
    padding-left: 5px;
    max-width: 300px;
    margin: 0 15px 0 auto;
}

.right_column {
    padding-right: 5px;
    margin: 0 auto 0 15px;
    max-width: 300px;
}

.column {
    min-height: 100vh;
    background-color: #fff;
    margin: 0 auto;
    padding: 7px 7px 10px 7px;
    width: 100%;
    max-width: var(--max-width);
    height: fit-content;
    display: flex;
    flex-flow: row wrap;
    gap: 7px;
}

.column.record_column {}

.column .row {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 7px;
    width: 100%;
}

.item_cell {
    height: var(--height_trashElement);
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    display: grid;
    grid-auto-flow: dense;
    grid-template-rows: 2fr 1fr;
    background-color: var(--rgb_color);
}

.item_cell * {
    display: block;
}

.item_cell .mediaElement {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    transition: opacity .1s;
}

.item_cell.unusual:hover .mediaElement {
    opacity: .6;
}

.item_cell.unusual .mediaElement .gradient {
    display: block;
    width: 100%;
    height: 30%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(var(--rgb_color_aplha) 0%, var(--rgb_color) 100%);
}

.item_cell.unusual .mediaElement .media {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: rgb(255, 255, 255);
}

.item_cell.unusual .contentElement {
    height: calc(100% - 30px);
    width: calc(100% - 40px);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    gap: 10px;
    padding: 15px 20px;
}

.item_cell.unusual .title {
    font-size: 20px;
    font-weight: 600;
}

.item_cell.unusual .title,
.item_cell.unusual .footnote {
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow: hidden;
    text-align: left;
}

.column .row[data-count_child="2"][data-grow_child="1"] {
    grid-template-columns: 2fr 1fr;
}

.column .row[data-count_child="2"][data-grow_child="2"] {
    grid-template-columns: 1fr 2fr;
}

.column .row[data-count_child="2"][data-grow_child="1"]:not(.full) .item_cell:nth-child(1),
.column .row[data-count_child="2"][data-grow_child="2"]:not(.full) .item_cell:nth-child(2) {
    grid-template-rows: none;
    grid-template-columns: 1fr 1.5fr;
}

.column .row[data-count_child="2"][data-grow_child="1"]:not(.full) .item_cell:nth-child(1) .mediaElement,
.column .row[data-count_child="2"][data-grow_child="2"]:not(.full) .item_cell:nth-child(2) .mediaElement {
    grid-column: 2;
}

.column .row[data-count_child="2"][data-grow_child="1"]:not(.full) .item_cell:nth-child(1) .contentElement,
.column .row[data-count_child="2"][data-grow_child="2"]:not(.full) .item_cell:nth-child(2) .contentElement {
    grid-column: 1;
}

.column .row[data-count_child="2"][data-grow_child="2"]:not(.full).item_cell:nth-child(2) .title,
.column .row[data-count_child="2"][data-grow_child="1"]:not(.full).item_cell:nth-child(1) .title {
    font-weight: 600;
    line-height: 24px;
    font-size: 22px;
    line-clamp: 6;
    -webkit-line-clamp: 6;
}

.column .row[data-count_child="2"][data-grow_child="1"]:not(.full) .item_cell:nth-child(1) .gradient,
.column .row[data-count_child="2"][data-grow_child="2"]:not(.full) .item_cell:nth-child(2) .gradient {
    height: 100%;
    width: 30%;
    background: linear-gradient(to left, var(--rgb_color_aplha) 0%, var(--rgb_color) 100%);
}

.read_more {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 10px;
    background-color: white;
    width: fit-content;
    color: black;
    text-shadow: none;
    font-weight: 700;
    padding: 8px 12px 10px 12px;
    font-size: 18px;
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.4);
    transition: .2s;
}

.read_more:hover {
    box-shadow: 0 0 5px 1px rgba(255, 255, 255, 0.4) inset;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
}

.read_more:hover span {
    background-color: rgb(255, 255, 255);
}

.read_more span {
    display: block;
    background-color: black;
    mask-image: var(--i_arrow);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
    width: 25px;
    height: 25px;
    transition: .2s;
}

.row .col_wrapper {
    width: 100%;
    height: 100%;
    contain: paint;
}

.row .col_wrapper .sticky {
    max-height: var(--dynamic_height_full_item);
    display: flex;
    flex-flow: column wrap;
    gap: 7px;
    width: 100%;
    position: sticky;
    top: 0;
}

.item_cell.full {
    /*     overflow: initial; */
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
    padding: 20px 10px;
    min-height: 300px;
    height: fit-content;
    background-color: white;
}

.item_cell.full .title {
    font-size: 28px;
    font-weight: 600;
    line-height: 35px;
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 10px;
    margin: 10px 0;
    border-left: 4px solid rgb(0, 140, 221);
    background-color: rgba(0, 140, 221, 0.1);
}

.item_cell.full .mediaElement {
    max-height: 300px;
}

.item_cell.full .mediaElement .media {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.item_cell.full .description {
    word-break: break-word;
    word-wrap: break-word;
    white-space: pre-wrap;
    margin-top: 20px;
    max-width: 100%;
    position: sticky;
    bottom: 0;
    --max-width: 100%;
    min-height: 150px;
    height: fit-content;
    font-size: max(16pt, 12px) !important;
}

.item_cell.full .description img[style*='float: left'] {
    margin-right: 10px !important;
}

.item_cell.full .description img[style*='float: right'] {
    margin-left: 10px !important;
}

.item_cell.full .description img, .item_cell.full .description video {
    padding: 10px 0;
    clear: both !important;
    max-width: var(--max-width);
    max-height: 100vh;
    float: none !important;
}

.item_cell.full .description a[target='_blank'] img,
.item_cell.full .description a[target='_blank'] video {
    float: right !important;
}

.item_cell.full .description div {
    clear: both;
    height: fit-content;
}

.item_cell.full .description ul {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
}

.item_cell.full .description li {
    display: flex;
    flex-direction: row;
    list-style: none;
    align-items: center;
}

.item_cell.full .description li::before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #000000;
    margin-right: 5px;
}

.item_cell.full .description figure {
    margin: 0;
}

.item_cell.full .description blockquote,
.item_cell.full .description hr,
.item_cell.full .description p,
.item_cell.full .description h2,
.item_cell.full .description h1,
.item_cell.full .description h3,
.item_cell.full .description ol,
.item_cell.full .description dl {
    margin-top: 3px;
    margin-bottom: 3px;
    max-width: 100%;
}

.item_cell.full .description p {
    font-size: max(16pt, 12px) !important;
}

.item_cell.full .description>p {
    margin: 5px 0px;
    padding-bottom: 20px;
}

.item_cell.full .description>p:last-child {
    padding-bottom: 0;
}

.item_cell.full .description span {
    display: inline-block;
    font-size: max(16pt, 12px) !important;
}

.item_cell.full .description span, .item_cell.full .description img {
    max-width: 100%;
    height: auto;
}

/* angry popup */
.angry_popup {
    position: fixed;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.backdrop {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #0000009c;
    backdrop-filter: blur(5px);
    z-index: 1;
}

.angry_wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 900px;
    height: fit-content;
    background-color: white;
    max-width: 90vw;
    max-height: 95vh;
    border-radius: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 2;
}

.angry_wrapper .content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-auto-flow: dense;
    gap: 10px;
    padding: 20px;
}

.angry_wrapper .content .item_cell {
    border-radius: 10px;
}

.angry_wrapper .head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0 0 0;
}

.angry_wrapper .head .title {
    font-size: 22px;
    font-weight: 700;
}

.angry_wrapper .close {
    position: absolute;
    right: 15px;
    top: 50%;
    width: 12px;
    height: 12px;
    transform: rotate(45deg) scale(2);
}

.angry_wrapper .close .top,
.angry_wrapper .close .bot {
    display: block;
    background: gray;
    position: absolute;
    top: 50%;
    left: 50%;
    animation-timing-function: cubic-bezier(1, 0, 0, 1);
    animation-iteration-count: 1;
}

.angry_wrapper .close .top {
    height: 100%;
    width: 2px;
    margin-left: -1px;
    top: 0;
}

.angry_wrapper .close .bot {
    width: 100%;
    height: 2px;
    margin-top: -1px;
    left: 0;
}

@keyframes top-out {
    from {
        top: 0%;
        height: 100%;
    }

    to {
        top: 200%;
        height: 0%;
    }
}

@keyframes top-in {
    from {
        top: -100%;
        height: 0%;
    }

    to {
        top: 0%;
        height: 100%;
    }
}

@keyframes bot-out {
    from {
        left: 0%;
        width: 100%;
    }

    to {
        left: 200%;
        width: 0%;
    }
}

@keyframes bot-in {
    from {
        left: -100%;
        width: 0%;
    }

    to {
        left: 0%;
        width: 100%;
    }
}

.angry_wrapper .close:hover {
    cursor: pointer;
}

.angry_wrapper .close:hover .top {
    animation: top-out 0.2s forwards, top-in 0.2s forwards 0.25s;
}

.angry_wrapper .close:hover .bot {
    animation: bot-out 0.2s forwards 0.1s, bot-in 0.2s forwards 0.2s;
}

.slider_container {
    width: 100%;
    height: 100%;
}

.media_news {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slider_media_clubok_24 {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    transition: .2s;
}

.slider_media_clubok_24::-webkit-scrollbar {
    height: 0;
}

.slider_media_clubok_24 img,
.slider_media_clubok_24 video {
    position: relative !important;
    min-width: 100% !important;
    padding: 0 !important;
}


.lazy_media_clubok24 {
    animation: backgroundDark 1.2s 1s ease-in infinite;
    background-color: var(--bg_color_loader);
}

@keyframes backgroundDark {
    from, to {
        background-color: var(--bg_color_loader);
    }

    50% {
        background-color: rgb(214, 214, 214);
    }
}

.more_block {
    margin: 20px 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: end;
    justify-content: space-between;
    padding: 10px 0;
}

.full_share_ok {
    width: fit-content;
    margin: 0 0 0 auto;
}

.full_share_ok iframe:not(:first-child) {
    display: none;
}

/* rating (five stars) */





.rating_wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: fit-content;
    align-items: left;
}

.rating_title {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
}

.rating_stars_numbers {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.rating_stars_box {
    display: flex;
    flex-direction: row-reverse;
    font-size: 22px;
}

.rating_stars_box:after {
    content: "";
    display: table;
    clear: both;
}

.star_rating_label {
    padding-left: 2px;
    cursor: pointer;
    color: #FFB300;
}

.star_rating_label:last-child {
    padding-left: 0;
}

.star_rating_input {
    display: none;
}

.star_rating_label:hover:before,
.star_rating_label:hover~.star_rating_label:before,
.star_rating_checked:before {
    content: "\f005";
}

.star_rating_label:hover {
    color: #ffdc8a;
}

.rating_numbers {
    position: relative;
    height: 100%;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    font-size: 16px;
    font-weight: 600;
}

.rating_popularity {
    position: absolute;
    left: 100%;
    top: -10px;
    font-size: 11px;
    font-weight: 100;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(102, 102, 102);
}

.score_name {
    word-wrap: nowrap;
    white-space: nowrap;
    width: 70px;
    min-width: 70px;
}

.score_row {
    background: linear-gradient(to bottom, #ececec, #dddddd);
    width: 100%;
    height: 60%;
}

.score_row_rate {
    height: 100%;
    width: 0;
    max-width: 100%;
    background-color: #FED500;
    background: linear-gradient(to bottom, #FED500, #d6b300)
}

.score_number {
    width: 15px;
    min-width: 15px;
    text-align: center;
    padding-left: 7px;
}

.afterRating_message {
    margin: 10px 0;
    font-size: 16px;
    color: #00A200;
}

/* share */

.share_social_block {
    margin: 0 auto;
    width: fit-content;
    clear: both;
}

.share_social_block span {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 20px;
}

.share_social_block .title_social {
    font-size: 22px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 600;
    display: flex;
    flex-flow: row nowrap;
    gap: 5px;
}


.share_social_block .arrow {
    font-size: 26px;
    animation: moveDown .6s linear infinite;
}

@keyframes moveDown {
    from, to {
        transform: translateY(-7px);
    }

    50% {
        transform: translateY(7px);
    }
}


.social_row_items {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    transform: scale(1);
}

.share_icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    transition: all .4s cubic-bezier(.68, -.55, .265, 1.55);
    margin-bottom: 3px;
}


.share_tooltip {
    background: #fff;
    padding: 10px 18px;
    border-radius: 25px;
    position: absolute;
    top: -70px;
    z-index: 5;
    box-shadow: 0 10px 10px rgba(0, 0, 0, .1);
    display: none;
    user-select: none;
    white-space: nowrap;
    transition: all .4s cubic-bezier(.68, -.55, .265, 1.55);
    color: white;
    font-size: 20px;
}

.share_icon:hover .share_tooltip {
    display: initial;
}

.share_icon:hover .share_button,
.share_icon:hover .share_tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, .4);
}

.share_tooltip::before {
    content: '';
    position: absolute;
    height: 15px;
    width: 15px;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    box-shadow: 0 10px 10px rgba(0, 0, 0, .1);
    z-index: 4;
}

.share_button {
    position: relative;
    z-index: 2;
    width: 42px;
    height: 42px;
    padding: 0;
    border: 0;
    outline: none;
    cursor: pointer;
    background: var(--i_social_icons) no-repeat scroll transparent;
    border-radius: 5px;
    overflow: hidden;
    transition: .5s;
}

.share_button:hover {
    transform: scale(1.1);
}

.vk {
    background-position: -167px 0;
}

.ok {
    background-position: -125px 0;
}

.tg {
    background-position: right 0;
}

.wh {
    background-position: -82px 0;
}

.pin {
    background-position: -209px 0;
}


.tw {
    background-position: -41px 0;
}

.fb {
    background-position: 1px 0;
}

.ok_icon .share_tooltip::before,
.ok_icon:hover .share_tooltip,
.ok_icon:hover .share_tooltip::before {
    background-color: #FF9700;
}

.vk_icon .share_tooltip::before,
.vk_icon:hover .share_tooltip,
.vk_icon:hover .share_tooltip::before {
    background-color: #2277FC;
}

.tg_icon .share_tooltip::before,
.tg_icon:hover .share_tooltip,
.tg_icon:hover .share_tooltip::before {
    background-color: #0095E1;
}

.wh_icon .share_tooltip::before,
.wh_icon:hover .share_tooltip,
.wh_icon:hover .share_tooltip::before {
    background-color: #00A200;
}

.pin_icon .share_tooltip::before,
.pin_icon:hover .share_tooltip,
.pin_icon:hover .share_tooltip::before {
    background-color: #F01719;
}

.tw_icon .share_tooltip::before,
.tw_icon:hover .share_tooltip,
.tw_icon:hover .share_tooltip::before {
    background-color: #00ADF3;
}

.fb_icon .share_tooltip::before,
.fb_icon:hover .share_tooltip,
.fb_icon:hover .share_tooltip::before {
    background-color: #3B5999;
}