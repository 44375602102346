/* default styles */
.more_button {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #325A83;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    box-orient: vertical;
    -webkit-box-orient: vertical;
    text-decoration: underline;
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.pseudo_info_button {
    display: block;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 41px;
    height: 20px;
    background-image: var(--i_info_close);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 1;
}

.footnote {
    font-size: 16px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
}

.adv_mini {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    width: 100%;
    height: 160px;
    margin: 0 0 20px 0;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 6px 1px rgba(165, 165, 165, 0.2);
    border-radius: 5px;
    background-color: #fff;
}

.adv_mini .slider_container {
    width: 45%;
    min-width: 100px;
    position: relative;
    overflow: hidden;
}

.adv_mini .slider_container .media_news {
    width: 100px;
}

.adv_mini .title_wrapper {
    -webkit-line-clamp: 4;
    line-clamp: 4;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    width: fit-content;
    height: fit-content;
    margin: auto 0;
    padding: 0 10px;
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
}

@keyframes bg_transition {
    0%, 100% {
        border-bottom: 2px solid rgb(0, 214, 0);
    }

    50% {
        border-bottom: 2px dashed rgb(0, 214, 0);
    }
}

/* inner records container */
.inner_records_adv {
    height: fit-content;
}

.inner_records_adv .adv_mini.plety {
    height: 100%;
    padding: 0;
}

.inner_records_adv .adv_mini.plety .slider_container {
    max-width: 130px;
    min-height: 120px;
    height: 100%;
}

.inner_records_adv .adv_mini.plety .slider_container video,
.inner_records_adv .adv_mini.plety .slider_container img,
.inner_records_adv .adv_mini.plety .slider_container .slider_media_clubok_24 {
    min-height: 120px;
}

.inner_records_adv .adv_mini.plety .slider_container .slider_media_clubok_24 {
    height: 100%;
    width: auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.inner_records_adv .adv_mini.plety .text_content {
    padding: 10px 0;
}

.inner_records_adv .adv_mini.plety .footnote {
    position: absolute;
    left: 80px;
    bottom: 0;
    padding: 0 7px;
    min-width: 130px;
    max-width: 60%;
    border-radius: 7px 7px 0 0;
    box-shadow: -2px 3px 6px 1px rgba(165, 165, 165, 0.7);
    line-height: 25px;
    animation: bg_transition .5s ease-in-out infinite;
    background-color: #fff;
    border-bottom: 2px solid rgb(0, 214, 0);
    -webkit-line-clamp: 1;
    line-clamp: 1;
}







/* LEFT-RIGHT COLUMNS */



.left_column .adv_mini .title_wrapper {
    padding: 0;
    font-size: 16px;
    margin: 0;
}

.left_column .adv_mini .text_content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
}


.left_column .adv_mini .footnote {
    line-clamp: 2 !important;
    -webkit-line-clamp: 2 !important;
}

.left_column .slider_container img,
.left_column .slider_container video {
    transition: transform .3s;
}

.left_column .adv_mini:hover img,
.left_column .adv_mini:hover video {
    transform: scale(1.1);
}

.left_column .more_button:hover {
    text-decoration: none;
    color: black;
}

.right_column .adv_mini {
    margin: 0;
    border-radius: 0;
    height: 100%;
    box-shadow: none;
}

.right_column .adv_mini:hover .title_wrapper {
    text-decoration: underline;
}

.right_column .adv_mini .slider_container {
    height: 150px;
    width: 100px;
    margin: 0;
}


.right_column .adv_mini .text_content {
    padding: 10px 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    position: relative;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.right_column .adv_mini .title_wrapper {
    font-size: 15px;
    padding: 0;
    line-clamp: 5;
    -webkit-line-clamp: 5;
    line-height: 16px;
    margin: 0;
    font-weight: normal;
}

.left_column .adv_mini .footnote,
.right_column .adv_mini .footnote {
    line-clamp: 1;
    -webkit-line-clamp: 1;
    margin: 5px 0 0 0;
    max-width: 100%;
    position: static;
    animation: none;
    box-shadow: none;
    border: none;
    border-radius: 0;
    background-color: transparent;
    line-height: 14px;
    font-size: 15px;
    padding: 0;
}

.left_column .adv_mini .about_block,
.right_column .adv_mini .about_block {
    display: flex;
    flex-flow: column nowrap;
    gap: 5px;
}

.left_column .adv_mini .creation_time,
.right_column .adv_mini .creation_time {
    font-size: 13px;
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgb(58, 58, 58);
    margin: auto 0 0 0;
}

.adv_news_container {
    background-color: #fff;
    padding: 0 5px 10px 0;
    width: 100%;
}

.adv_news_container .title {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    padding: 20px 0 25px 10px;
}

.adv_block_elements {
    background-color: #fff;
    display: flex;
    flex-flow: column nowrap;
}

/* plety blocks (mini) */

.adv_mini.plety {
    width: calc(100% - 15px);
    padding: 10px 10px;
    border-radius: 0;
}

/* lone block (large) */

.adv_mini.lone {
    width: 100%;
    height: auto;
    flex-flow: column nowrap;
    border-radius: 0;
    margin: 20px 0 20px 0;
    box-shadow: none;
    background-color: #EFF7FF;
}

.adv_mini.lone .slider_container {
    position: relative;
    width: 100%;
}

.adv_mini.lone .text_content {
    display: flex;
    flex-flow: column nowrap;
    gap: 5px;
    padding: 10px 15px;
}

.adv_mini.lone .text_content .footnote {
    position: static;
}

.adv_mini.lone .text_content .title_wrapper {
    padding: 0;
    line-height: 25px;
    font-size: 20px;
    line-clamp: 6;
    -webkit-line-clamp: 6;
}