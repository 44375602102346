html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block
}

html {
  min-width: 100vw;
  height: 100%
}

body {
  width: 100%;
  height: 100%;
  line-height: 1;
  font-family: Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

ol, ul {
  list-style: none
}

:root {
  --max-width: 800px;
  --height_trashElement: 270px;
  --width_elem_mobile: 340px;
  --mini_trash_element_calc: calc(33% - 2px);
  --max_trash_element_width: calc(66% - 2px);
  --rgb_color_aplha: black;
  --rgb_color: black;
  --dynamic_height_full_item: auto;

  --i_arrow: url(./icons/arrow-right_1.png);
  --i_info_close: url(./icons/info-close.jpg);
  --i_social_icons: url(./icons/social_icons.png)
}

a, a:hover, a:visited {
  color: black;
  text-decoration: none;
}

blockquote, q {
  quotes: none
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  float: right !important;
  width: auto !important;
  word-wrap: break-word !important;
  word-break: break-all;
}

iframe {
  clear: both !important;
}

.disable_hover {
  pointer-events: none !important;
}